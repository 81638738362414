.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.element {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: gray;
  margin: 0 5px;
}
.quas {
  background: aqua;
}
.wex {
  background: magenta;
}
.exort {
  background: orange;
}

.skills {
  text-align: left;
  font-family: monospace;
  padding: 20px;
}
